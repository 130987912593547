import React, { useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import ReactPlayer from "react-player";
import { IoIosPlay } from "react-icons/io";
import { Container, Row, Col, Carousel, Button, Modal } from "react-bootstrap";

import * as styles from "./pdp-media-carousel.module.scss";

function ImageSlide({ mediaItem }) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  return (
    <div onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
      {mediaItem.mediaContentType === "VIDEO" && (
        <div className={styles.playIconDiv}>
          <IoIosPlay color="white" />
        </div>
      )}
      <GatsbyImage
        className="pdp-carousel-productimage"
        image={mediaItem.preview.image.gatsbyImageData}
        alt="photo"
        style={{ cursor: "zoom-in" }}
      />
      <Modal
        show={open}
        onHide={toggle}
        dialogClassName="modal-90w"
        aria-labelledby={mediaItem.shopifyId + "-image"}
        size="lg"
        key={mediaItem.shopifyId}
      >
        <Modal.Body>
          {mediaItem.mediaContentType === "IMAGE" && (
            <GatsbyImage
              image={mediaItem.preview.image.gatsbyImageData}
              alt="photo"
              style={{ cursor: "zoom-out" }}
            />
          )}

          {mediaItem.mediaContentType === "VIDEO" && (
            <GatsbyImage
              image={mediaItem.preview.image.gatsbyImageData}
              alt="photo"
              style={{ cursor: "zoom-out" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
function VideoSlide({ mediaItem }) {
  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };
  const sources = mediaItem.sources.map(item => {
    const src = [];
    src.src = item.url;
    src.type = item.mimeType;
    return src;
  });
  return (
    <>
      <div onClick={toggle} onKeyDown={toggle} role="button" tabIndex={0}>
        {mediaItem.mediaContentType === "VIDEO" && (
          <div className={styles.playIconDiv}>
            <IoIosPlay color="white" />
          </div>
        )}
      </div>
      <GatsbyImage
        image={mediaItem.preview.image.gatsbyImageData}
        alt="photo"
        style={{ cursor: "zoom-out" }}
      />
      <Modal
        show={open}
        onHide={toggle}
        dialogClassName="modal-90w"
        aria-labelledby={mediaItem.shopifyId + "-image"}
        size="xl"
        key={mediaItem.shopifyId}
      >
        <Modal.Body>
          {mediaItem.mediaContentType === "IMAGE" && (
            <GatsbyImage
              image={mediaItem.preview.image.gatsbyImageData}
              alt="photo"
              style={{ cursor: "zoom-out" }}
            />
          )}
          <ReactPlayer
            playing
            controls
            width="100%"
            height="100%"
            url={sources}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button color="secondary" onClick={toggle} role="button" tabIndex={0}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

// TODO: make the Carousel controlled by thumbnails
// TODO: fix the modals
function ControlledCarousel({ mediaItems, contentType }) {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const hasMultipleSlides = mediaItems.length > 1 ? true : false;
  let filteredMediaItems = mediaItems.filter(function(e) {
    return e.mediaContentType === contentType;
  });
  return (
    <Container fluid>
      <Row>
        <Col>
          <Carousel
            activeIndex={index}
            onSelect={handleSelect}
            interval={5000}
            variant="dark"
            controls={hasMultipleSlides}
            indicators={hasMultipleSlides}
          >
            {filteredMediaItems.map(mediaItem => (
              <Carousel.Item key={mediaItem.shopifyId} as="div">
                {contentType === "VIDEO" && (
                  <VideoSlide mediaItem={mediaItem} />
                )}
                {contentType === "IMAGE" && (
                  <ImageSlide mediaItem={mediaItem} />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </Container>
  );
}

export default ControlledCarousel;
