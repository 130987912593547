/** @format */

import React from "react";
import { Row, Col, Button, Tabs, Tab } from "react-bootstrap";
import convert from "react-from-dom";
import PdpMediaCarousel from "../components/compositions/pdp-media-carousel";

const ProductTemplate = ({ pageContext }) => {
  const { product } = pageContext;
  const desc = product.descriptionHtml
    ? convert("<div>" + product.descriptionHtml + "</div>")
    : null;
  const hasVids =
    product.media.filter(function (e) {
      return e.mediaContentType === "VIDEO";
    }).length > 0;

  const hasImgs =
    product.media.filter(function (e) {
      return e.mediaContentType === "IMAGE";
    }).length > 0;
  return (
    <>
      <Row>
        <Col sm={4}>
          {hasImgs ? (
            <Col>
              <PdpMediaCarousel
                mediaItems={product.media}
                contentType="IMAGE"
              />
            </Col>
          ) : (
            <Col
              style={{
                backgroundColor: "rgb(239, 239, 239)",
                display: "flex",
                alignItems: "center",
                textAlign: "right",
                height: "240px",
                color: "#444",
              }}
            >
              &nbsp; &nbsp; Photo Coming Soon
            </Col>
          )}
        </Col>
        <Col>
          <h1
            style={{
              color: "#0e4888",
              fontSize: "32px",
            }}
          >
            United {product.extraFields.number} {product.extraFields.name}{" "}
            <span style={{ color: "rgb(65, 142, 21)" }}>
              {product.extraFields.subtitle}
            </span>
          </h1>
          {desc}
          <hr />
          <Row className="pdp-pdf-buttons-row">
            {product.extraFields.catalog_sheet_filename && (
              <Col md={4} className="d-grid gap-2">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  href={
                    "https://assets.unitedlabsmdsupply.com/CatalogSheets/" +
                    product.extraFields.catalog_sheet_filename
                  }
                  target="_blank"
                >
                  Catalog Sheet
                </Button>
              </Col>
            )}

            {product.extraFields.sds_filename && (
              <Col md={4} className="d-grid gap-2">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  href={
                    "https://assets.unitedlabsmdsupply.com/SDS/" +
                    product.extraFields.sds_filename
                  }
                  target="_blank"
                >
                  SDS
                </Button>
              </Col>
            )}
            {product.extraFields.label_filename && (
              <Col md={4} className="d-grid gap-2">
                <Button
                  variant="outline-secondary"
                  size="sm"
                  href={
                    "https://assets.unitedlabsmdsupply.com/Labels/" +
                    product.extraFields.label_filename
                  }
                  target="_blank"
                >
                  Product Label
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Row className="pdp-extrainfo-tabs-row">
        <Col>
          <Tabs
            defaultActiveKey="Specifications"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            {product.extraFields.specs && (
              <Tab eventKey="Specifications" title="Specs">
                Specifications: <br />
                <ul>
                  {Object.keys(product.extraFields.specs).map((key, index) => (
                    <div key={index}>
                      {product.extraFields.specs[key] != null && (
                        <li>
                          {
                            key
                              .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
                              .replace(
                                /[-_]+(.)/g,
                                (_, c) => " " + c.toUpperCase()
                              ) // First char after each -/_
                          }
                          : {product.extraFields.specs[key]}
                          <br />
                        </li>
                      )}
                    </div>
                  ))}
                </ul>
              </Tab>
            )}
            {product.extraFields.applications && (
              <Tab eventKey="Applications" title="Applications">
                {product.extraFields.applications.introtext}:
                <ul>
                  {product.extraFields.applications.items.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </Tab>
            )}
          </Tabs>
        </Col>
        {hasVids && (
          <Col>
            <PdpMediaCarousel mediaItems={product.media} contentType="VIDEO" />
          </Col>
        )}
      </Row>
    </>
  );
};

export default ProductTemplate;
